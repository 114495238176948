<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addFleetSectionsModal' hide-footer>
        <addFleetSections @closeAddFleetSections='toggleAddFleetSections()' @addFleetSectionsSuccess='addFleetSectionsSuccess()'></addFleetSections>
      </b-modal>
      <b-modal ref='editFleetSectionsModal' hide-footer>
        <editFleetSections
          :editingFleetSections='currentFleetSections'
          @closeEditFleetSections='toggleEditFleetSections()'
          @editFleetSectionsSuccess='editFleetSectionsSuccess()'
        ></editFleetSections>
      </b-modal>
      <div class='row'>
        <h2>FleetSections</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddFleetSections()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addFleetSections from '../../components/addFleetSections';
import editFleetSections from '../../components/editFleetSections';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Thumbnail',
                field: 'Thumbnail',
                sort: 'asc',
              },
              {
                label: 'PanelHeading',
                field: 'PanelHeading',
                sort: 'asc',
              },
              {
                label: 'PanelCopy',
                field: 'PanelCopy',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addFleetSectionsOpen: false,
      editFleetSectionsOpen: false,
      currentFleetSections: {}
    };
  },
  components: {
    addFleetSections,
    editFleetSections,
    Datatable,
  },
  created: function() {
    this.getFleetSections();
    this.addFleetSectionsOpen = false;
    this.editFleetSectionsOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getFleetSections() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getFleetSections', payload);
    },
    editItem(FleetSections) {
      this.toggleEditFleetSections();
      this.currentFleetSections = FleetSections;
    },
    toggleAddFleetSections() {
      if(this.addFleetSectionsOpen)
      {
        this.$refs.addFleetSectionsModal.hide()
      }
      else{
        this.$refs.addFleetSectionsModal.show()
      }
      this.addFleetSectionsOpen = !this.addFleetSectionsOpen;
    },
    addFleetSectionsSuccess() {
      this.toggleAddFleetSections();
      miniToastr['success']('FleetSections Added', 'Success', 1000, null);
      this.getFleetSections();
    },
    toggleEditFleetSections() {
      if(this.editFleetSectionsOpen)
      {
        this.$refs.editFleetSectionsModal.hide()
      }
      else{
        this.$refs.editFleetSectionsModal.show()
      }
      this.editFleetSectionsOpen = !this.editFleetSectionsOpen;
    },
    editFleetSectionsSuccess() {
      this.toggleEditFleetSections();
      miniToastr['success']('FleetSections Edited', 'Success', 1000, null);
      this.getFleetSections();
    }
  }
};
</script>
<style scoped>
.FleetSectionsThumbnail {
  height: 50px;
}
</style>
