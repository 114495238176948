<template>
  <div class='container'>
  <h2>Add A FleetSections</h2>
   <button @click='save()' type='button' class='btn btn-success'>Save</button>
  <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
	<div class='row'>
            <div class=' col-md-4'><label class='' for='Thumbnail'>Thumbnail</label><input class=' form-control'   
                id='Thumbnail' name='Thumbnail' v-model='newFleetSections.Thumbnail' placeholder='Thumbnail' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='PanelHeading'>PanelHeading</label><input class=' form-control'   
                id='PanelHeading' name='PanelHeading' v-model='newFleetSections.PanelHeading' placeholder='PanelHeading' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='PanelCopy'>PanelCopy</label><input class=' form-control'   
                id='PanelCopy' name='PanelCopy' v-model='newFleetSections.PanelCopy' placeholder='PanelCopy' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
<div class='col-md-4'>
    <label class for='Image'>Image</label>
    <div v-if='!editingImage'>
      <img v-bind:src='getImageSource(newFleetSections)' class='previewImage'>
      <button @click='editImage()' type='button' class='btn btn-success'>Update Image</button>
    </div>
    <div v-if='editingImage'>
      <button @click='updateImage()' type='button' class='btn btn-success'>Update</button>
      <button @click='editImage()' type='button' class='btn btn-warning'>Cancel</button>
      <FileUploader @ImageDataChanged='updateImageData' @ImageURLDataChanged='updateImageUrl'/>
    </div>
  </div>
            <div class=' col-md-4'><label class='' for='LastUpdated'>LastUpdated</label><input class=' form-control'   
                id='LastUpdated' name='LastUpdated' v-model='newFleetSections.LastUpdated' placeholder='LastUpdated' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
    <br/>
            </div>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
    </div>
    
</template>
<script>
import _ from 'lodash'; 
import FileUploader from '@/components/custom/FileUploader.vue'; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  name: 'addFleetSections',
  created: function() {
  },
  data() {
      return {
          newFleetSections: {},
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
        editingImage: false 
      }
  },
  components: { 
    FileUploader 
  }, 
  methods: {
    toggleView: function() {
      this.$emit('closeAddFleetSections')
    },
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
            return true;
        }
    return false;
    },
    save: function() {
    var payload = {
          data: this.newFleetSections,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('addFleetSectionsSuccess')
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('addFleetSections', payload);
    }
  }
}
</script>
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
